import _ from "lodash";
import { hardwareProducts } from "../selectors";
import * as OrdersProductAPI from "../../../api/v1/orderProduct";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Make OrderProduct calls for hardware.
 * @param retry {boolean}
 * @returns {Function}
 */
export const orderHardware = createAsyncThunk(
  "hardwareOrders/orderHardware",
  async (retry: boolean = false, { getState, dispatch }) => {
    const state: any = getState();
    const products = hardwareProducts(state);
    return await Promise.all(
      products.map(async (p: any) => {
        // Don't send two calls at once.
        // Although tbh this shouldn't happen anyway as getOrderProductCallsFetching() stops nav in the UI.
        if (_.get(state.hardwareOrders, `${p.identifier}.fetching`)) return;

        // Check if the product's already in the order. (If it is, update instead of create)
        const orderProductId = _.get(
          state.hardwareOrders,
          `${p.identifier}.response.data.id`
        );

        // If we're just retrying, skip if there's an order product ID
        if (retry && orderProductId) return;

        const shouldUpdate = !!orderProductId;
        const { identifier, id } = p;
        const params = {
          ...p.params,
          account_id: state.order.accountId,
          order_id: state.order.id,
        };
        dispatch(
          orderProduct({ orderProductId, shouldUpdate, identifier, params, id })
        );
      })
    );
  }
);

export const orderProduct = createAsyncThunk(
  "hardwareOrders/orderProduct",
  async ({ orderProductId, shouldUpdate, identifier, params, id }: any) => {
    const response = shouldUpdate
      ? await OrdersProductAPI.update(orderProductId, params, identifier)
      : await OrdersProductAPI.create(id, params, identifier);
    return { identifier, shouldUpdate, response };
  }
);
